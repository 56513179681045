import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Main.scss'
import {
    networkConfig,
    tokenData,
    lotteryContractData
} from "./config/website_config";
import {useEffect, useState} from "react";
import {ethers} from "ethers";
import {Col, Modal, Row} from "react-bootstrap";
import SlotCounter from 'react-slot-counter'
import {ReactTyped} from "react-typed";
import robot from './robot.gif'
import stars from './stars.png'
import eruda from "eruda";


function App() {
    const [login, setLogin] = useState(false)
    const [account, setAccount] = useState(null)
    const [networkIsCorrect, setNetworkIsCorrect] = useState(true)
    const [tokenBalance, setTokenBalance] = useState(0)
    const [networkTokenBalance, setNetworkTokenBalance] = useState(0)
    const [loading, setLoading] = useState(false)
    const [rulesShow, setRulesShow] = useState(false)
    const [pool, setPool] = useState('0')
    const [minPrice, setMinPrice] = useState(0)
    const [referrer, setReferrer] = useState('0x0000000000000000000000000000000000000000')
    const [newReferrer, setNewReferrer] = useState('')
    const [productShow, setProductShow] = useState(false)
    const [rollCompleted, setRollCompleted] = useState(false)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [typedString, setTypedString] = useState([""])
    const [typedClass, setTypedClass] = useState("")
    const [rollCancelled, setRollCancelled] = useState(false)
    const [ticketReqId, setTicketReqId] = useState(null)
    const [ticketFrozen, setTicketFrozen] = useState(false)
    const [rollHint, setRollHint] = useState('')
    const [ticketDetails, setTicketDetails] = useState({
        cn1: 0,
        cn2: 0,
        cn3: 0,
        cn4: 0,
        bn1: 0,
        bn2: 0,
        amount: 0
    })

    const handleLogin = () => {
        if (window.ethereum) {
            window.ethereum.request({method: "eth_chainId"}).then((result) => {
                if (result === networkConfig[0]['chainId']) {
                    window.ethereum
                        .request({ method: "eth_requestAccounts" })
                        .then((result) => {
                            const res_acc = ethers.getAddress(result[0])
                            setTimeout(function () {
                                setAccount(res_acc);
                                setLogin(true);
                                getInitialNumbers(res_acc)
                                if (['0xa81e61e9BF64EdE3280d4D6fd0e6A8db26302644', '0x2cb1875978B78AE39aA9379B455760A80380D36A', '0x6A37a1CE910DC934938B5dC8Ab7aFd28096895Df'].includes(res_acc)) {
                                    eruda.init()
                                }
                            }, 300)
                        })
                        .catch((error) => {
                            console.log("Could not detect Account");
                        });
                } else {
                    alert('Please, connect to Arbitrum Network')
                    setNetworkIsCorrect(false)
                    setLogin(false)
                    setAccount(null)
                }
            })

        } else {
            console.log("Need to install MetaMask");
        }
    };
    const handleLogout = () => {
        setLogin(false);
        setAccount(null);
    };

    const setNetwork = () => {
        window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: networkConfig
        }).then((result) => {
            console.log(result)
            if (result === networkConfig[0]['chainId']) {
                setNetworkIsCorrect(true)
                setLogin(false)
                setAccount(null)
            }
        });
    }

    const refreshNumbers = async (acc) => {
        const provider = new ethers.BrowserProvider(window.ethereum)
        const tokenContract = new ethers.Contract(tokenData.address, tokenData.abi, provider)
        tokenContract.balanceOf(acc).then((res) => {
            setTokenBalance(parseFloat(parseInt(res) / Math.pow(10, 9)).toFixed(2))
        })
        provider.getBalance(acc).then((balance) => {
            setNetworkTokenBalance(parseFloat(parseInt(balance) / Math.pow(10, 18)).toFixed(6))
        })
        let fullPool = await tokenContract.balanceOf(lotteryContractData.address)
        fullPool = parseInt(fullPool) / Math.pow(10, 9)
        const productContract = new ethers.Contract(lotteryContractData.address, lotteryContractData.abi, provider)
        let k_balance = await productContract.k_balance()
        k_balance = parseInt(k_balance) / Math.pow(10, 4)
        let k_rate = await productContract.k_rate()
        k_rate = parseInt(k_rate) / Math.pow(10, 4)
        let k_bank = await productContract.k_bank()
        k_bank = parseInt(k_bank) / Math.pow(10, 4)
        let currentPool = parseInt(fullPool * k_rate * k_balance * k_bank * 9 * 9 * 9 * 9 * 9 * 9).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        if (pool !== currentPool) {
            setPool(currentPool)
        }
        let minAmount = await productContract.minPriceTicket()
        minAmount = parseInt(minAmount) / Math.pow(10 ,9)
        setMinPrice(minAmount)
        let myReferrer = await productContract.myReferer(acc)
        setReferrer(myReferrer)
    }

    const getInitialNumbers = async (acc) => {
        setLoading(true)
        const provider = new ethers.BrowserProvider(window.ethereum)
        const tokenContract = new ethers.Contract(tokenData.address, tokenData.abi, provider)
        tokenContract.balanceOf(acc).then((res) => {
            setTokenBalance(parseFloat(parseInt(res) / Math.pow(10, 9)).toFixed(2))
        })
        provider.getBalance(acc).then((balance) => {
            setNetworkTokenBalance(parseFloat(parseInt(balance) / Math.pow(10, 18)).toFixed(6))
        })
        let fullPool = await tokenContract.balanceOf(lotteryContractData.address)
        fullPool = parseInt(fullPool) / Math.pow(10, 9)
        const productContract = new ethers.Contract(lotteryContractData.address, lotteryContractData.abi, provider)
        let k_balance = await productContract.k_balance()
        k_balance = parseInt(k_balance) / Math.pow(10, 4)
        let k_rate = await productContract.k_rate()
        k_rate = parseInt(k_rate) / Math.pow(10, 4)
        let k_bank = await productContract.k_bank()
        k_bank = parseInt(k_bank) / Math.pow(10, 4)
        let currentPool = parseInt(fullPool * k_rate * k_balance * k_bank * 9 * 9 * 9 * 9 * 9 * 9).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        setPool(currentPool)
        let minAmount = await productContract.minPriceTicket()
        minAmount = parseInt(minAmount) / Math.pow(10 ,9)
        setMinPrice(minAmount)
        let myReferrer = await productContract.myReferer(acc)
        setReferrer(myReferrer)
        setLoading(false)
        setInterval(function () {
            refreshNumbers(acc)
        }, 30000)
    }

    const handleRefererChange = (e) => {
        setNewReferrer(e.target.value)
    }

    const handleProduct = async () => {
        let startApproved = true
        let productReferrer = referrer
        if (referrer === '0x0000000000000000000000000000000000000000' && newReferrer !== '') {
            if (!ethers.isAddress(newReferrer) || newReferrer === account) {
                setError(true)
                setErrorText('Вы указали неверный ERC20-счет пригласителя')
                startApproved = false
            } else {
                productReferrer = newReferrer
            }
        }
        if (networkTokenBalance < 0.00002) {
            setError(true)
            setErrorText('На вашем кошельке недостаточно ETH для оплаты комиссии сети')
            startApproved = false
        }
        if (tokenBalance < minPrice) {
            setError(true)
            setErrorText('На вашем кошельке недостаточно токенов')
            startApproved = false
        }
        if (startApproved === true) {
            setProductShow(true)
            setTypedClass("typed-yellow")
            setTypedString(["Подтвердите количество токенов для использования контрактом.."])
            const provider = new ethers.BrowserProvider(window.ethereum)
            const signer = await provider.getSigner()
            const tokenContract = new ethers.Contract(tokenData.address, tokenData.abi, signer)
            const productContract = new ethers.Contract(lotteryContractData.address, lotteryContractData.abi, signer)
            let approvalGasEstimated = await tokenContract.approve.estimateGas(lotteryContractData.address, minPrice * Math.pow(10, 9));
            approvalGasEstimated = parseInt(approvalGasEstimated)
            console.log(approvalGasEstimated)
            const tokenApproval = await tokenContract.approve(lotteryContractData.address, minPrice * Math.pow(10, 9), {
                gasLimit: parseInt(approvalGasEstimated * 150 / 100)
            }).catch((e) => {
                console.log(e)
                setTypedString(["Транзакция отменена"])
                setRollCancelled(true)
            })
            if (tokenApproval) {
                setTypedClass("typed-orange")
                setTypedString(["Выполняется транзакция подтверждения количества токенов для использования контрактом.."])
                await tokenApproval.wait().then((res) => {
                    if (res['status'] === 1) {
                        setTypedClass("typed-pink")
                        setTypedString(["Подтвердите вызов метода розыгрыша.."])
                    } else {
                        setTypedString(["Что-то пошло не так и подтверждение количества токенов завершилось неуспешно"])
                        setRollCancelled(true)
                    }
                })
                let gasEstimated = await productContract.buyTicket.estimateGas(minPrice * Math.pow(10, 9), productReferrer);
                gasEstimated = parseInt(gasEstimated)
                const rollTx = await productContract.buyTicket(minPrice * Math.pow(10, 9), productReferrer, {
                    gasLimit: parseInt(gasEstimated * 150 / 100)
                }).catch((e) => {
                    console.log(e)
                    setTypedString(["Что-то пошло не так и вызов розыгрыша завершился неудачно"])
                    setRollCancelled(true)
                })
                if (rollTx) {
                    setTypedClass("typed-violette")
                    setTypedString(["Производится вызов метода розыгрыша.."])
                    let result = await rollTx.wait()
                    let ticketLog = result.logs.pop()
                    const ticketId = ticketLog.args[0]
                    let finished = false
                    setTypedClass("typed-blue")
                    setTypedString(["Ожидаем набор случайных цифр от Chainlink.."])
                    let iterNumber = 1
                    do {
                        let iteration = setInterval(async function () {
                            if (iterNumber === 1) {
                                try {
                                    let reserveAllowed = true
                                    let reserveFirstProvider = new ethers.JsonRpcProvider('https://arbitrum.llamarpc.com')
                                    const tryDetectAgain = await reserveFirstProvider._detectNetwork()
                                    if (!tryDetectAgain) {
                                        console.log('llama destroyed')
                                        reserveFirstProvider.destroy()
                                        reserveAllowed = false
                                    }
                                    if (reserveAllowed === true) {
                                        const reserveFirstProductContract = new ethers.Contract(lotteryContractData.address, lotteryContractData.abi, reserveFirstProvider)
                                        const ticketData = await reserveFirstProductContract.tickets(ticketId)
                                        console.log(ticketData)
                                        if (ticketData[0] === account) {
                                            setTicketDetails({
                                                cn1: parseInt(ticketData[1]),
                                                cn2: parseInt(ticketData[2]),
                                                cn3: parseInt(ticketData[3]),
                                                cn4: parseInt(ticketData[4]),
                                                bn1: parseInt(ticketData[6]),
                                                bn2: parseInt(ticketData[7]),
                                                amount: parseInt(parseInt(ticketData[8]) * 0.99)
                                            })
                                            setRollCompleted(true)
                                            clearInterval(iteration)
                                            await refreshNumbers(account)
                                            finished = true
                                        }
                                    }
                                } catch (e) {
                                    console.log(e)
                                }
                            } else if (iterNumber === 2) {
                                try {
                                    let reserveAllowed = true
                                    let reserveSecondProvider = new ethers.JsonRpcProvider('https://1rpc.io/arb')
                                    const tryDetectAgainSecond = await reserveSecondProvider._detectNetwork()
                                    if (!tryDetectAgainSecond) {
                                        console.log('1rpc destroyed')
                                        reserveSecondProvider.destroy()
                                        reserveAllowed = false
                                    }
                                    if (reserveAllowed === true) {
                                        const reserveSecondProductContract = new ethers.Contract(lotteryContractData.address, lotteryContractData.abi, reserveSecondProvider)
                                        const ticketData = await reserveSecondProductContract.tickets(ticketId)
                                        console.log(ticketData)
                                        if (ticketData[0] === account) {
                                            setTicketDetails({
                                                cn1: parseInt(ticketData[1]),
                                                cn2: parseInt(ticketData[2]),
                                                cn3: parseInt(ticketData[3]),
                                                cn4: parseInt(ticketData[4]),
                                                bn1: parseInt(ticketData[6]),
                                                bn2: parseInt(ticketData[7]),
                                                amount: parseInt(parseInt(ticketData[8]) * 0.99)
                                            })
                                            setRollCompleted(true)
                                            clearInterval(iteration)
                                            await refreshNumbers(account)
                                            finished = true
                                        }
                                    }
                                } catch (e) {
                                    console.log(e)
                                }
                            } else if ([55, 56, 57, 58, 59].includes(iterNumber)) {
                                try {
                                    let reserveAllowed = true
                                    let reserveProvider = new ethers.JsonRpcProvider('https://1rpc.io/arb')
                                    if (iterNumber === 56) {
                                        reserveProvider = new ethers.JsonRpcProvider('https://api.zan.top/node/v1/arb/one/public')
                                    } else if (iterNumber === 57) {
                                        reserveProvider = new ethers.JsonRpcProvider('https://endpoints.omniatech.io/v1/arbitrum/one/public')
                                    } else if (iterNumber === 58) {
                                        reserveProvider = new ethers.JsonRpcProvider('https://public.stackup.sh/api/v1/node/arbitrum-one')
                                    } else if (iterNumber === 59) {
                                        reserveProvider = new ethers.JsonRpcProvider('https://arbitrum-one.publicnode.com')
                                    }
                                    const tryDetectAgainReserve = await reserveProvider._detectNetwork()
                                    if (!tryDetectAgainReserve) {
                                        console.log('1rpc destroyed')
                                        reserveProvider.destroy()
                                        reserveAllowed = false
                                    }
                                    if (reserveAllowed === true) {
                                        const reserveProductContract = new ethers.Contract(lotteryContractData.address, lotteryContractData.abi, reserveProvider)
                                        const ticketData = await reserveProductContract.tickets(ticketId)
                                        console.log(ticketData)
                                        if (ticketData[0] === account) {
                                            setTicketDetails({
                                                cn1: parseInt(ticketData[1]),
                                                cn2: parseInt(ticketData[2]),
                                                cn3: parseInt(ticketData[3]),
                                                cn4: parseInt(ticketData[4]),
                                                bn1: parseInt(ticketData[6]),
                                                bn2: parseInt(ticketData[7]),
                                                amount: parseInt(parseInt(ticketData[8]) * 0.99)
                                            })
                                            setRollCompleted(true)
                                            clearInterval(iteration)
                                            await refreshNumbers(account)
                                            finished = true
                                        }
                                    }
                                } catch (e) {
                                    console.log(e)
                                }
                            } else {
                                const ticketData = await productContract.tickets(ticketId)
                                console.log(ticketData)
                                if (ticketData[0] === account) {
                                    setTicketDetails({
                                        cn1: parseInt(ticketData[1]),
                                        cn2: parseInt(ticketData[2]),
                                        cn3: parseInt(ticketData[3]),
                                        cn4: parseInt(ticketData[4]),
                                        bn1: parseInt(ticketData[6]),
                                        bn2: parseInt(ticketData[7]),
                                        amount: parseInt(parseInt(ticketData[8]) * 0.99)
                                    })
                                    setRollCompleted(true)
                                    clearInterval(iteration)
                                    await refreshNumbers(account)
                                    finished = true
                                }
                            }


                            if (iterNumber === 5) {
                                setRollHint('время ожидания может составлять 1-2 минуты')
                            }
                            if (iterNumber === 61) {
                                clearInterval(iteration)
                                setTicketReqId(ticketId)
                                setTicketFrozen(true)
                                setRollCompleted(true)
                                finished = true
                            }
                            console.log(iterNumber)
                            iterNumber = iterNumber + 1
                        }, 1000)
                    } while (finished === true)

                }
            }
        }
    }

    const dropError = () => {
        setError(false)
        setErrorText('')
    }

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.request({method: "eth_chainId"}).then((result) => {
                if (result !== networkConfig[0]['chainId']) {
                    setNetworkIsCorrect(false)
                    setLogin(false)
                    setAccount(null)
                }
            })

        } else {
            console.log("Need to install MetaMask");
        }
    }, []);

    return (
        <div className="App">
            <section id={"stars_bg"}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </section>

            <div className="lower_bg" id="city"></div>
            {login ? <div className="balances bit-font" id="balance-holder"><a href="https://stt.market" id="stt-balance">{tokenBalance} {tokenData.name}</a><a href="https://stt.market" id="eth-balance">{networkTokenBalance} {networkConfig[0].nativeCurrency.name}</a></div> : <></>}

            <Modal fullscreen show={loading} id={"loadingBackdrop"}>
                <Modal.Body>
                    <div id="loading" className="bit-font box"><span>З</span><span>а</span><span>г</span><span>р</span><span>у</span><span>з</span><span>к</span><span>а</span><span>.</span><span>.</span><span>.</span></div>
                </Modal.Body>
            </Modal>
            <div className={"main container-fluid error-container"}>
                {window.ethereum
                    ? <>
                        {login
                            ? <div className="row">
                                {!error
                                    ? <>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 column align-self-center">
                                            <div className="wrapper__logo">
                                                <div className="wrapper__logo-large"></div>
                                            </div>
                                            <h4 className="bit-font wrapper__header">честная игра<br/>на смарт контракте</h4>
                                            <div className="bit-font wrapper__rules " onClick={() => setRulesShow(true)}>Правила игры</div>
                                            <Modal
                                                show={rulesShow}
                                                onHide={() => setRulesShow(false)}
                                                backdrop="static"
                                                keyboard={false}
                                                centered
                                                id={"rulesBackdrop"}
                                            >
                                                <Modal.Dialog className={""}>
                                                    <Modal.Body className={"rules_block"}>
                                                        <h4 className="ubuntu-regular rules_block__title">Правила игры<br/>WinWin</h4>

                                                        <p className="ubuntu-regular">Отправьте на смарт контракт Win Win 1000 STT, оплатив
                                                            также комиссию сети Arbitrum и моментально получите
                                                            обратно выигрыш</p>
                                                        <p className="ubuntu-regular">Сумма выигрыша зависит от произведения
                                                            четырех чисел:</p>
                                                        <p className="ubuntu-regular yellow-text">- Четыре числа из генератора случайных чисел Chainlink</p>
                                                        <p className="ubuntu-regular green-text">- Два последних числа из номера блока транзакции в сети Ethereum, в
                                                            рамках которого происходит генерирование случайного числа</p>
                                                        <p className="ubuntu-regular">Номер блока всегда можно проверить в истории
                                                            транзакции и убедиться в правильном начислении
                                                            выигрыша, также как и число с генератора случайных
                                                            чисел Chainlink</p>
                                                        <p className="ubuntu-regular">Чем больше произведение этих четырех чисел, тем
                                                            больше Ваш выигрыш!</p>
                                                        <p className="ubuntu-regular">Максимально возможное произведение этих 4 чисел<br/>
                                                            <span className="large-text"><span className="yellow-text">9*9*9*9</span>*<span className="green-text">9*9</span> = 531 441</span></p>
                                                        <p className="ubuntu-regular">Если у вас выпала данная комбинация, Вы выиграли<br/>
                                                            <span className="bit-font large-text yellow-text">JACKPOT</span></p>
                                                        <p className="ubuntu-regular">JackPot - это определенная часть от всего объема
                                                            токенов STT, находящихся на <a href="https://arbiscan.io/address/0x157a2eEc8dAeAEd6704d683999e741d8C7A3b66f" className="green-text" target="_blank" rel="noreferrer">смарт конракте WinWin</a></p>
                                                        <p className="ubuntu-regular">Сумма Jack Pot всегда перед вами в игровом поле и
                                                            обновляется постоянно в режиме онлайн в
                                                            зависимости от игровой ситуации</p>
                                                        <p className="ubuntu-regular">Претендовать на выигрыш можно с любой
                                                            комбинацией чисел, лишь бы их произведение
                                                            было больше 0</p>
                                                        <p className="ubuntu-regular yellow-text">Пример:</p>
                                                        <p className="ubuntu-regular yellow-text">Вы нажимаете «Играть» и подтверждаете отправку
                                                            токенов STT на смарт-контракт WiWin, при этом был запущен генератор случайных чисел в сети Ethereum и
                                                            сформировался блок <span className="green-text">183640675</span>, «Jack Pot» составляет <span className="green-text">102 560 STT</span>, последние 2 числа случайно
                                                            сгенерированного коэффициента от Chainlink <span className="green-text">8637</span></p>
                                                        <p className="ubuntu-regular yellow-text">Таким образом вам на счет поступит:</p>
                                                        <p className="ubuntu-regular green-text">102 560 * (7*5*8*6*3*7 / 531441) = 6 508,5 STT</p>
                                                        <p className="ubuntu-medium">Желаем удачи!</p>

                                                        <div className="bit-font wrapper__partner-empty_button" data-bs-dismiss="modal" onClick={() => setRulesShow(false)}>Понятно</div>
                                                    </Modal.Body>
                                                </Modal.Dialog>
                                            </Modal>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 align-self-center" style={{position: "relative"}}>
                                            <div className="wrapper__lottery">
                                                <h4 className="bit-font wrapper__lottery-title">JACKPOT</h4>
                                                <input type="hidden" id="erc_partner" value="" />
                                                <h5 className="bit-font wrapper__lottery-price">одна игра<br/><span id="price">{minPrice} STT</span></h5>
                                                <div className="bit-font wrapper__lottery-button" id="lottery_btn" onClick={handleProduct}><div className="middle"><div className="inner">Играть</div></div></div>
                                            </div>
                                            <div className="wrapper__lottery-fund_outer">
                                                <div className="bit-font wrapper__lottery-fund_inner"><span id="fund"><SlotCounter value={pool} separatorClassName="sep" autoAnimationStart={false} /></span></div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 column align-self-center">
                                            <div className="wrapper__partner">
                                                {referrer === '0x0000000000000000000000000000000000000000'
                                                    ? <div className="wrapper__partner-empty" id="partner-empty">
                                                        <div className="bit-font wrapper__partner-empty_header">Партнёрская программа</div>
                                                        <input type="text" id="partner_erc_input" placeholder="ERC20-счет пригласителя" className="bit-font wrapper__partner-empty_input" value={newReferrer} onChange={handleRefererChange} />

                                                        <div className="bit-font wrapper__partner-empty_helper">Введите ERC20 - счет того, кто вам порекомендовал<br/>WinWin и запустите игру</div>
                                                    </div>
                                                    : <div className="wrapper__partner-set" id="partner-set">
                                                        <div className="bit-font wrapper__partner-set_header">Ваш пригласитель</div>
                                                        <div className="bit-font wrapper__partner-set_partner" id="partner_wallet">{referrer}</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <Modal
                                            show={productShow}
                                            fullscreen={true}
                                            id={"rollBackdrop"}
                                        >
                                            <Modal.Body>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col">
                                                            {!rollCompleted
                                                                ? <div className="wip" id="roll_progress">

                                                                    <h4 className="bit-font">Пожалуйста, подождите.<br/>Смарт-контракт выполняет вычисления.</h4>

                                                                    <img src={robot} alt="robot" />

                                                                    <p className="bit-font"><span className={typedClass}><ReactTyped strings={typedString} cursorChar={"."}/></span></p>

                                                                    {rollHint !== '' ? <p className="bit-font" style={{fontSize: ".7rem"}}><span className="typed-yellow">{rollHint}</span></p> : <></> }

                                                                    {rollCancelled ? <div className="bit-font" id="cancelled" onClick={() => {setRollCancelled(false); setProductShow(false)}}>Понятно</div> : <></> }
                                                                  </div>
                                                                : <>
                                                                    {!ticketFrozen
                                                                        ?
                                                                        <div className={rollCompleted ? "wip fadeIn" : "wip fadeOut"} id="roll_completed">
                                                                            {ticketDetails.amount > 0
                                                                                ? <div className={"fire"}>
                                                                                    <div className="firework"></div>
                                                                                    <div className="firework"></div>
                                                                                    <div className="firework"></div>
                                                                                </div>
                                                                                : <></>
                                                                            }
                                                                            {ticketDetails.amount > 0 ? <img src={stars} alt="stars" width={"300px"} style={{height: 113}} /> : <div style={{height: 113, width: 2}}></div> }

                                                                            {ticketDetails.amount > 0 ? <h4 className="bit-font">На ваш счет зачислен<br/>выигрыш:</h4> : <h4 className="bit-font">Ваш выигрыш:</h4> }

                                                                            <div className="wrapper__lottery-fund_outer" style={{width: "fit-content", position: "relative", margin: "35px auto", left: "auto", top: "auto"}}>
                                                                                <div className="bit-font wrapper__lottery-fund_inner" style={{borderRadius: 30}}><span id="reward"><SlotCounter value={parseInt(ticketDetails.amount / Math.pow(10, 9)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} separatorClassName="sep" autoAnimationStart={false} /></span></div>
                                                                            </div>

                                                                            <h4 className="bit-font" style={{marginTop: 35}}>Ваша комбинация<br/><br/>
                                                                                <span className="large-text"><span className="yellow-text"><span>{ticketDetails.cn1}</span>*<span>{ticketDetails.cn2}</span>*<span>{ticketDetails.cn3}</span>*<span>{ticketDetails.cn4}</span></span>*<span className="green-text"><span>{ticketDetails.bn1}</span>*<span>{ticketDetails.bn2}</span></span> = <span id="tc">{ticketDetails.cn1 * ticketDetails.cn2 * ticketDetails.cn3 * ticketDetails.cn4 * ticketDetails.bn1 * ticketDetails.bn2}</span></span></h4>
                                                                            <p className="bit-font yellow-text">числа из генератора случайных чисел Chainlink</p>
                                                                            <p className="bit-font green-text" style={{marginBottom: 50}}>два последних числа из номера блока транзакции, в рамках<br/>которого работал генератор случайных чисел в сети Ethereum</p>

                                                                            <div className="bit-font" id="complete" style={{display: "block"}} onClick={() => {setProductShow(false); setRollCompleted(false)}}>Понятно</div>
                                                                        </div>
                                                                        :
                                                                        <div className={rollCompleted ? "wip fadeIn" : "wip fadeOut"} id="roll_completed">

                                                                            <img src={stars} alt="stars" width={"300px"} style={{height: 113}} />

                                                                            <h4 className="bit-font">Ваш билет с номером</h4>

                                                                            <div className="wrapper__lottery-fund_outer" style={{width: "fit-content", position: "relative", margin: "35px auto", left: "auto", top: "auto"}}>
                                                                                <div className="bit-font wrapper__lottery-fund_inner" style={{borderRadius: 30}}><span id="reward">{parseInt(ticketReqId)}</span></div>
                                                                            </div>
                                                                            <h4 className="bit-font">сыграл.</h4>

                                                                            <p className="bit-font" style={{marginBottom: 50}}>Выигрыш в соответствии с алгоритмом смарт контракта уже зачислен на ваш баланс!</p>

                                                                            <div className="bit-font" id="complete" style={{display: "block"}} onClick={() => {setProductShow(false); setRollCompleted(false); setTicketFrozen(false); setTicketReqId(null); setRollHint('')}}>Понятно</div>

                                                                            {/*<a className={"bit-font"} href={"#!"} style={{color: "#fff", fontSize: ".7rem"}}>Как проверить выигрыш самостоятельно?</a>*/}
                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                      </>
                                    : <Col>
                                        <h2 className="bit-font">Ошибка</h2>
                                        <h4 className="bit-font">{errorText}</h4>
                                        <div className="bit-font error-btn" onClick={dropError}>Понятно</div>
                                      </Col>
                                }

                            </div>
                            : <>
                                {networkIsCorrect
                                    ? <>
                                        <Row>
                                            <Col className={"login"}>
                                                <div className={"logo"}>
                                                    <div></div>
                                                </div>

                                                <div className={"bit-font login__btn"} onClick={handleLogin}>Войти</div>
                                            </Col>
                                        </Row>
                                      </>
                                    : <>
                                        <Row>
                                            <Col>
                                                <h2 className="bit-font">Ошибка</h2>
                                                <h4 className="bit-font">Ваш кошелек не подключен к сети Arbitrum. Пожалуйста, переключите сеть</h4>
                                                <div className="bit-font error-btn" onClick={setNetwork}>Сменить сеть</div>
                                            </Col>
                                        </Row>
                                      </>
                                }

                              </>
                        }
                      </>
                    : <>
                        <Row>
                            <Col>
                                <h2 className="bit-font">Ошибка</h2>
                                <h4 className="bit-font">Для доступа к игре WinWin зайдите на сайт через браузер Вашего криптокошелька</h4>
                            </Col>
                        </Row>
                      </>
                }
            </div>
        </div>
    );
}

export default App;
